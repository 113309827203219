<template>
  <div>
    <DataTable
      v-model:filters="filters"
      :value="items"
      :loading="loading"
      :rows="10"
      lazy
      row-hover
      paginator
      scrollable
      :totalRecords="total"
      :rowsPerPageOptions="[10, 25, 50]"
      dataKey="id"
      filterDisplay="row"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      currentPageReportTemplate="Memuat {first} dari {last} data dari total {totalRecords}"
      @page="onPage($event)"
      @sort="onSort($event)"
      @filter="onFilter($event)"
    >
      <template #empty>
        <div class="text-center">Tidak ada data.</div>
      </template>
      <template #loading>
        <div class="text-center">Memuat data, harap tunggu...</div>
      </template>
      <Column
        field="si_no"
        header="No. Faktur"
        style="min-width: 12rem"
        headerStyle="min-width: 12rem"
        sortable
      >
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            @keydown.enter="filterCallback()"
          />
        </template>
      </Column>
      <Column
        dataType="date"
        filter-field="order_between"
        field="si_at"
        header="Tgl. Faktur"
        style="min-width: 12rem"
        headerStyle="min-width: 12rem"
        sortable
      >
        <template #body="{ data }">
          {{ formatDate(data.si_at) }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <CalendarFilter
            v-model="filterModel.value"
            @select="filterCallback()"
          />
        </template>
      </Column>
      <Column
        field="salesman.nama"
        sort-field="salesman"
        filter-field="salesman"
        header="Salesman"
        style="min-width: 12rem"
        headerStyle="min-width: 12rem"
        sortable
      >
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            @keydown.enter="filterCallback()"
          />
        </template>
      </Column>
      <Column
        field="pelanggan.nama"
        sort-field="pelanggan"
        filter-field="pelanggan"
        header="Pelanggan"
        style="min-width: 12rem"
        headerStyle="min-width: 12rem"
        sortable
      >
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            @keydown.enter="filterCallback()"
          />
        </template>
      </Column>
      <Column
        field="pelanggan_npwp"
        header="N.P.W.P"
        style="min-width: 12rem"
      />
      <Column
        field="sub_total"
        header="Bruto"
        style="min-width: 10rem; flex-grow: 1; justify-content: flex-end"
      >
        <template #body="{ data }">
          {{ formatCurrency(data.sub_total) }}
        </template>
      </Column>
      <Column
        field="dpp_si"
        header="Nett Faktur"
        style="min-width: 10rem; flex-grow: 1; justify-content: flex-end"
      >
        <template #body="{ data }">
          {{ formatCurrency(data.dpp_si) }}
        </template>
      </Column>
      <Column
        field="dpp_si"
        header="DPP"
        style="min-width: 10rem; flex-grow: 1; justify-content: flex-end"
      >
        <template #body="{ data }">
          {{ formatCurrency(data.dpp_si) }}
        </template>
      </Column>
      <Column
        field="ppn_si"
        header="PPN"
        style="min-width: 10rem; flex-grow: 1; justify-content: flex-end"
      >
        <template #body="{ data }">{{ formatCurrency(data.ppn_si) }} </template>
      </Column>
      <Column
        field="jumlah"
        header="Nilai Penjualan"
        style="min-width: 10rem; flex-grow: 1; justify-content: flex-end"
      >
        <template #body="{ data }">
          {{ formatCurrency(data.jumlah) }}
        </template>
      </Column>
      <Column
        field="pajak_no"
        header="No Faktur Pajak"
        style="min-width: 12rem"
      />
      <Column
        field="dpp_si"
        header="DPP Faktur Pajak"
        style="min-width: 10rem; flex-grow: 1; justify-content: flex-end"
      >
        <template #body="{ data }">
          {{ formatCurrency(data.dpp_si) }}
        </template>
      </Column>
      <Column
        field="ppn_si"
        header="PPN Faktur Pajak"
        style="min-width: 10rem; flex-grow: 1; justify-content: flex-end"
      >
        <template #body="{ data }">
          {{ formatCurrency(data.ppn_si) }}
        </template>
      </Column>
      <Column header="Tgl Lunas" style="min-width: 12rem">
        <template #body="{ data }">
          {{ formatDate(data.date_paid) }}
        </template>
      </Column>
      <Column
        field="jumlah"
        header="Jumlah Lunas"
        style="min-width: 10rem; flex-grow: 1; justify-content: flex-end"
      >
        <template #body="{ data }">
          {{ formatCurrency(data.jumlah) }}
        </template>
      </Column>
      <Column
        field="jumlah_total"
        header="Jumlah Dibayar"
        style="min-width: 10rem; flex-grow: 1; justify-content: flex-end"
      >
        <template #body="{ data }">
          {{ formatCurrency(data.jumlah_total) }}
        </template>
      </Column>
      <Column
        field="cn"
        header="CN"
        style="min-width: 10rem; flex-grow: 1; justify-content: flex-end"
      >
        <template #body="{ data }">
          {{ formatCurrency(data.cn) }}
        </template>
      </Column>
      <Column
        field="dn"
        header="DN"
        style="min-width: 10rem; flex-grow: 1; justify-content: flex-end"
      >
        <template #body="{ data }">
          {{ formatCurrency(data.dn) }}
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import { formatCurrency, formatDate } from '@/helpers'
import { FilterMatchMode } from 'primevue/api'
import CalendarFilter from '@/components/CalendarFilter'

export default {
  components: {
    CalendarFilter,
  },
  props: {
    items: {
      type: Array,
      default() {
        return []
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    total: {
      type: Number,
      default: 0,
    },
    gridOptions: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      options: this.gridOptions,
      filters: {
        si_no: { value: null, matchMode: FilterMatchMode.CONTAINS },
        order_between: { value: null, matchMode: FilterMatchMode.CONTAINS },
        pelanggan: { value: null, matchMode: FilterMatchMode.CONTAINS },
        salesman: { value: null, matchMode: FilterMatchMode.CONTAINS },
        status: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
    }
  },
  methods: {
    onPage(e) {
      e.page = e.page + 1
      e.sortField = this.options.sortField
      e.sortOrder = this.options.sortOrder
      this.options = e
      this.options.filters = this.filters
      this.loadData()
    },
    onSort(e) {
      this.options = e
      this.loadData()
    },
    onFilter() {
      this.options.page = 1
      this.options.filters = this.filters
      this.loadData()
    },
    loadData() {
      this.$emit('request', this.options)
    },
    formatCurrency(value, minDigit = 0, maxDigit = 0) {
      return formatCurrency(value, minDigit, maxDigit)
    },
    formatDate(value) {
      return formatDate(value)
    },
  },
}
</script>
